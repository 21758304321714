import React, { useState } from "react";
import { Box } from "@mui/material";
import { firebaseDateToText } from "../../../common/utils";
import { generateFirebaseImageUrl } from "../../../common/utils/helpers";
import { CustomTooltip, CustomTooltipContent } from "./CustomTooltip";
import MapMarkerIcon from "./MapMarkerIcon";
import MapMarkerModal from "./MapMarkerModal";

interface MapMarkerProps {
  lat: number;
  lng: number;
  user: any;
  type: 'clockIn' | 'clockOut' | 'history' | 'taskEnd';
  hovered: boolean;
  onClickDriver: () => void;
  time: any;
  onHover?: () => void;
  onLeave?: () => void;
  color?: string;
}

const MapMarker: React.FC<MapMarkerProps> = ({ user, type, hovered, onClickDriver, time, onHover, onLeave, color }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userDetails: [string, string][] = [
    [type === 'clockOut' ? 'Clock Out' : type === 'clockIn' ? 'Clock In' : '', firebaseDateToText(time)]
  ];

  const iconSize = type === 'history' ? 15 : 26;

  const handleMarkerClick = () => {
    if (type === 'clockIn' || type === 'clockOut') {
      setIsModalOpen(true);
    }
    onClickDriver();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getImageUrl = () => {
    if (type === 'clockIn' && user.clockIn && user.clockIn.image) {
      return generateFirebaseImageUrl('timesheet', user.clockIn.image);
    } else if (type === 'clockOut' && user.clockOut && user.clockOut.image) {
      return generateFirebaseImageUrl('timesheet', user.clockOut.image);
    }
    return "";
  };

  return (
    <>
      <CustomTooltip open={hovered} title={<CustomTooltipContent userDetails={userDetails} />} placement="bottom" arrow>
        <Box
          sx={{ cursor: 'pointer', width: iconSize }}
          onClick={handleMarkerClick}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
        >
          <MapMarkerIcon type={type} size={iconSize} color={color} />
        </Box>
      </CustomTooltip>
      <MapMarkerModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={type as 'clockIn' | 'clockOut'}
        userDetails={userDetails}
        imageUrl={getImageUrl()}
      />
    </>
  )
}

export default MapMarker;