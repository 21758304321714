import React from "react";
import { Box, Stack, styled, SxProps, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#444',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
  },
}));

interface CustomTooltipContentProps {
  userDetails: [string, string][];
}

const CustomTooltipContent: React.FC<CustomTooltipContentProps> = ({ userDetails }) => {
  const textSx: SxProps = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' };

  return (
    <Box>
      {userDetails.map((deets, index) => (
        <Stack key={index} direction="row" alignItems="center" gap={1}>
          {deets[0] && <Typography variant="body2" sx={textSx}>
            {deets[0]}:
          </Typography>}
          <Typography variant="body2" sx={{ ...textSx, fontWeight: 800 }}>
            {deets[1]}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

export { CustomTooltip, CustomTooltipContent };