import React from "react";
import { Modal, Paper, Typography, Box } from "@mui/material";

interface MapMarkerModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'clockIn' | 'clockOut';
  userDetails: [string, string][];
  imageUrl: string;
}

const MapMarkerModal: React.FC<MapMarkerModalProps> = ({ isOpen, onClose, type, userDetails, imageUrl }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="image-modal"
      aria-describedby="clock-in-out-image"
    >
      <Paper sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '500px',
        maxHeight: '90vh',
        overflow: 'auto',
        p: 3,
        outline: 'none',
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          {type === 'clockIn' ? 'Clock In Details' : 'Clock Out Details'}
        </Typography>
        <Box mb={2}>
          {userDetails.map((detail, index) => (
            <Typography key={index} variant="body1">
              <strong>{detail[0]}:</strong> {detail[1]}
            </Typography>
          ))}
        </Box>
        <img src={imageUrl} alt={`${type} image`} style={{ width: '100%', height: 'auto' }} />
      </Paper>
    </Modal>
  );
};

export default MapMarkerModal;