import React from "react";
import { FaSignInAlt, FaSignOutAlt, FaStar } from "react-icons/fa";

interface MapMarkerIconProps {
  type: 'clockIn' | 'clockOut' | 'history' | 'taskEnd';
  size: number;
  color?: string;
}

const MapMarkerIcon: React.FC<MapMarkerIconProps> = ({ type, size, color }) => {
  switch (type) {
    case 'clockOut':
      return <FaSignOutAlt size={size} color="#001529" />;
    case 'history':
      return (
        <div
          style={{
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundColor: '#4CAF50',
          }}
        />
      );
    case 'taskEnd':
      return <FaStar size={size} color={color || "#4CAF50"} />;
    case 'clockIn':
    default:
      return <FaSignInAlt size={size} color="#eec022" />;
  }
};

export default MapMarkerIcon;