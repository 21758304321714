import React, { useEffect, useState, useCallback } from 'react';
import { Container } from '../../../components/common/Containers'

import { Span } from '../../../components/common/Span';
import { FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, Stack, styled, Switch, Typography, useMediaQuery, useTheme, ListItemIcon, Paper, Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { doc, getFirestore, onSnapshot, collection, query, where, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import MapMarker from '../Map/MapMarker';
import { firebaseDateToText, generateFirebaseImageUrl } from '../../../common/utils';
import Loader from '../../../components/Loader';
import { Person, Badge, Business, Work, AccessTime, ExitToApp, Assignment, Update, Star } from '@mui/icons-material';
import TimesheetStatsDashboard from '../../../components/Card/Render/TimesheetStatsDashboard';

interface HistoryLocation {
  latitude: number;
  longitude: number;
  time: string; // Assuming time is stored as a string, adjust if it's a different type
}

interface Task {
  taskName: string;
  remarks: string;
  startTime: {
    time: any;
    location: {
      name: string;
      latitude: number;
      longitude: number;
    };
  };
  endTime: {
    time: any;
    location: {
      name: string;
      latitude: number;
      longitude: number;
    };
  };
  status: string;
  startTaskImage: string[];
  endTaskImage: string[];
  attachedImages: string[];
}

const CustomSwitch = styled(Switch)(() => ({
  padding: 6,
  width: '80px',
  height: '34px',
  '& .MuiSwitch-switchBase': {
    padding: '7px',
    '&.Mui-checked': {
      transform: 'translateX(45px)',
      '& + .MuiSwitch-track::before': {
        opacity: 1
      },
      '& + .MuiSwitch-track::after': {
        opacity: 0
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    color: '#fff',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&::before': {
      content: '"Show"',
      left: 14,
      opacity: 0,
    },
    '&::after': {
      content: '"Hide"',
      right: 18,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

let UNSUBSCRIBE: any;

const AllTimesheetTrack = () => {
  let { timesheetId } = useParams<{timesheetId: string}>();
  const db = getFirestore();

  const theme = useTheme();
  const mobile = useMediaQuery('(max-width:600px)')
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  
  let [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [showClockIn, setShowClockIn] = useState(true);
  const [showClockOut, setShowClockOut] = useState(true);
  const [activeMarker, setActiveMarker] = useState<any>(null);
  const [hoveredHistoryMarker, setHoveredHistoryMarker] = useState<number | null>(null);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [taskLines, setTaskLines] = useState<google.maps.Polyline[]>([]);
  const [selectedTaskImages, setSelectedTaskImages] = useState<{start: string[], end: string[], attached: string[]}>({
    start: [],
    end: [],
    attached: []
  });

  const defaultCenter = { lat: 14.59, lng: 120.98 };
  const defaultZoom = 11;
  const [center, setCenter] = useState<{ lat: number; lng: number }>(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const [historyLocations, setHistoryLocations] = useState<HistoryLocation[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [mapsInstance, setMapsInstance] = useState<typeof google.maps | null>(null);

  const getData = async () => {
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    UNSUBSCRIBE = onSnapshot(doc(db, "timesheet", timesheetId), (doc) => {
      if (doc.exists()) {
        const docData = doc.data();
        setData(docData);
        // Explicitly type the history data
        setHistoryLocations(docData.history as HistoryLocation[] || []);
        setIsLoading(false);
      } else {
        console.log("No such document!");
        setIsLoading(false);
      }
    });
  };

  const getTasks = async () => {
    const tasksQuery = query(collection(db, "tasks"), where("timesheetId", "==", timesheetId));
    const querySnapshot = await getDocs(tasksQuery);
    const tasksList: Task[] = [];
    querySnapshot.forEach((doc) => {
      const taskData = doc.data() as Task;
      // Only add tasks with valid endTime and location
      if (taskData.endTime && taskData.endTime.time && taskData.endTime.location) {
        tasksList.push(taskData);
      } else {
        console.warn(`Task ${taskData.taskName} has invalid endTime or location and will be skipped.`);
      }
    });
    // Sort tasks by end time in descending order
    const sortedTasks = tasksList.sort((a, b) => {
      const aTime = a.endTime.time.seconds || a.endTime.time;
      const bTime = b.endTime.time.seconds || b.endTime.time;
      return bTime - aTime;
    });
    setTasks(sortedTasks);
  };

  useEffect(() => {
    getData();
    getTasks();
  }, []);

  useEffect(() => {
    if (data?.clockIn?.location) {
      setCenter({ lat: data.clockIn.location.latitude, lng: data.clockIn.location.longitude});
    }
  }, [data?.clockIn?.location])
  
  const userDetails = Object.keys(data).reduce((acc: any, key) => {
    switch (key) {
      case 'userInfo':
        acc[0] = ['Name', data.userInfo.name];
        acc[1] = ['Employee Number', data.userInfo.employeeNumber];
        acc[2] = ['Department', data.userInfo.department];
        acc[3] = ['Position', data.userInfo.position];
        break;
      case 'clockIn':
        acc[4] = ['Clock In', firebaseDateToText(data.clockIn.time)];
        break;
      case 'clockOut':
        if (data.clockOut && data.clockOut.time) {
          acc[5] = ['Clock Out', firebaseDateToText(data.clockOut.time)];
        }
        break;
      default:
        break;
    }
    return acc
  }, [])

  const onClickMarker = (user, type) => {
    if (activeMarker === user[type].location) {
      setActiveMarker(null);
      // setCenter(defaultCenter);
      setZoom(defaultZoom);
    } else {
      setActiveMarker(user[type].location);
      setCenter({ lat: user[type].location.latitude, lng: user[type].location.longitude });
      setZoom(15);
    }
  };

  const getIconForDetail = (detailName: string) => {
    switch (detailName) {
      case 'Name':
        return <Person />;
      case 'Employee Number':
        return <Badge />;
      case 'Department':
        return <Business />;
      case 'Position':
        return <Work />;
      case 'Clock In':
        return <AccessTime />;
      case 'Clock Out':
        return <ExitToApp />;
      default:
        return null;
    }
  };

  const renderHistoryPath = (map: google.maps.Map, maps: typeof google.maps) => {
    const path = historyLocations.map(location => ({
      lat: location.latitude,
      lng: location.longitude
    }));

    const polyline = new maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    polyline.setMap(map);
  };

  const renderTaskPaths = useCallback((map: google.maps.Map, maps: typeof google.maps) => {
    // Clear existing lines
    taskLines.forEach(line => line.setMap(null));
    
    const newLines = tasks.map((task, index) => {
      // Check if both start and end locations are valid
      if (task.startTime && task.startTime.location && task.endTime && task.endTime.location &&
          isValidCoordinate(task.startTime.location.latitude, task.startTime.location.longitude) &&
          isValidCoordinate(task.endTime.location.latitude, task.endTime.location.longitude)) {
        const path = [
          { lat: task.startTime.location.latitude, lng: task.startTime.location.longitude },
          { lat: task.endTime.location.latitude, lng: task.endTime.location.longitude }
        ];

        const polyline = new maps.Polyline({
          path: path,
          geodesic: true,
          strokeColor: getTaskColor(index),
          strokeOpacity: selectedTask === task.taskName ? 1.0 : 0.5,
          strokeWeight: selectedTask === task.taskName ? 3 : 2
        });

        polyline.setMap(map);
        return polyline;
      }
      return null;
    }).filter((line): line is google.maps.Polyline => line !== null);

    setTaskLines(newLines);
  }, [tasks, selectedTask]);

  useEffect(() => {
    if (mapInstance && mapsInstance) {
      renderTaskPaths(mapInstance, mapsInstance);
    }
  }, [renderTaskPaths, mapInstance, mapsInstance]);

  const onHistoryMarkerHover = (index: number | null) => {
    setHoveredHistoryMarker(index);
  };

  const handleTaskClick = (taskName: string) => {
    const clickedTask = tasks.find(task => task.taskName === taskName);
    if (clickedTask) {
      console.log('Clicked task:', taskName);
      console.log('Start location:', clickedTask.startTime.location);
      console.log('End location:', clickedTask.endTime.location);
      setSelectedTaskImages({
        start: clickedTask.startTaskImage,
        end: clickedTask.endTaskImage,
        attached: clickedTask.attachedImages
      });
    }
    setSelectedTask(taskName === selectedTask ? null : taskName);
  };

  // Add this function to generate colors for tasks
  const getTaskColor = (index: number) => {
    const colors = ['#FFA500', '#00CED1', '#FF69B4', '#32CD32', '#FF4500', '#1E90FF', '#FFD700', '#8A2BE2', '#00FF7F', '#FF1493'];
    return colors[index % colors.length];
  };

  const isValidCoordinate = (lat: number, lng: number) => {
    return !isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0 && 
           lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  };

  // Add this function to calculate the number of completed tasks
  const getCompletedTasksCount = () => {
    return tasks.filter(task => task.status === 'completed').length;
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <Container border='grey' padding='none'>
        <div className='p-4'>
          <div className={`flex justify-center items-center`}>
            <Span margin='mt-4' weight='bold' size='2xl'>Timesheet Details</Span>
          </div>
        </div>

        <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
          <Grid container spacing={2} p={2} {...(!downLG && { height: '700px' })}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <List dense sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {userDetails.map((details, index) => (
                    <ListItem
                      key={index}
                      sx={{ 
                        width: 'auto', 
                        minWidth: '200px', 
                        flexGrow: 1, 
                        maxWidth: '300px',
                        ...(index % 2 === 1 && {bgcolor: 'rgba(0, 0, 0, 0.04)'}),
                      }}
                    >
                      <ListItemIcon>
                        {getIconForDetail(details[0])}
                      </ListItemIcon>
                      <ListItemText 
                        primary={details[0]} 
                        secondary={details[1]}
                        primaryTypographyProps={{ variant: 'body2', fontWeight: 'bold' }}
                        secondaryTypographyProps={{ variant: 'body1' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
              <Box sx={{ position: 'relative', height: downLG ? '400px' : '550px' }}>
                <GoogleMapReact
                  center={center}
                  defaultZoom={defaultZoom}
                  draggable={true}
                  zoom={zoom}
                  options={{
                    streetViewControl: true,
                    styles: [
                      {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "on" }],
                      },
                    ],
                  }}
                  bootstrapURLKeys={{ key: process.env.REACT_APP_API_MAP_KEY || "" }}
                  onGoogleApiLoaded={({ map, maps }: { map: google.maps.Map, maps: typeof google.maps }) => {
                    setMapInstance(map);
                    setMapsInstance(maps);
                    renderHistoryPath(map, maps);
                    renderTaskPaths(map, maps);
                  }}
                  yesIWantToUseGoogleMapApiInternals
                >
                  {data?.clockOut?.location && isValidCoordinate(data.clockOut.location.latitude, data.clockOut.location.longitude) && (
                    <MapMarker
                      lat={data.clockOut.location.latitude}
                      lng={data.clockOut.location.longitude}
                      user={data}
                      type='clockOut'
                      hovered={data.clockOut.location === activeMarker}
                      onClickDriver={() => onClickMarker(data, 'clockOut')}
                      time={data.clockOut.time}
                    />
                  )}
                  {data?.clockIn?.location && isValidCoordinate(data.clockIn.location.latitude, data.clockIn.location.longitude) && (
                    <MapMarker
                      lat={data.clockIn.location.latitude}
                      lng={data.clockIn.location.longitude}
                      user={data}
                      type='clockIn'
                      hovered={data.clockIn.location === activeMarker}
                      onClickDriver={() => onClickMarker(data, 'clockIn')}
                      time={data.clockIn.time}
                    />
                  )}
                  {historyLocations.map((location, index) => (
                    isValidCoordinate(location.latitude, location.longitude) && (
                      <MapMarker
                        key={index}
                        lat={location.latitude}
                        lng={location.longitude}
                        user={data}
                        type='history'
                        hovered={hoveredHistoryMarker === index}
                        onClickDriver={() => {}}
                        time={location.time}
                        onHover={() => onHistoryMarkerHover(index)}
                        onLeave={() => onHistoryMarkerHover(null)}
                      />
                    )
                  ))}
                  {tasks.map((task, index) => (
                    task.endTime && task.endTime.location && 
                    isValidCoordinate(task.endTime.location.latitude, task.endTime.location.longitude) && (
                      <MapMarker
                        key={`task-end-${index}`}
                        lat={task.endTime.location.latitude}
                        lng={task.endTime.location.longitude}
                        user={data}
                        type='taskEnd'
                        hovered={selectedTask === task.taskName}
                        onClickDriver={() => handleTaskClick(task.taskName)}
                        time={task.endTime.time}
                        color={getTaskColor(index)}
                      />
                    )
                  ))}
                </GoogleMapReact>
                {/* Task Images Pane */}
                {selectedTask && (
                  <Paper 
                    elevation={3} 
                    sx={{ 
                      position: 'absolute',
                      top: 10,
                      left: 10,
                      width: '300px',
                      height: 'calc(100% - 20px)',
                      p: 2, 
                      display: 'flex', 
                      flexDirection: 'column',
                      opacity: 0.9,
                      '&:hover': {
                        opacity: 1,
                      },
                      overflowY: 'auto'
                    }}
                  >
                    {tasks.find(task => task.taskName === selectedTask && task.endTime && task.endTime.location) && (
                      <Box mb={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px', p: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ borderBottom: '1px solid #ddd', pb: 1, mb: 2 }}>
                          {selectedTask}
                        </Typography>
                        <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic', color: 'text.secondary', mb: 2 }}>
                          {tasks.find(task => task.taskName === selectedTask)?.remarks}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2" color="primary">Start</Typography>
                            <Typography variant="body2">
                              {firebaseDateToText(tasks.find(task => task.taskName === selectedTask)?.startTime.time, 'MM/DD/YYYY hh:mm a')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold' }}>
                              {tasks.find(task => task.taskName === selectedTask)?.startTime.location.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2" color="primary">End</Typography>
                            <Typography variant="body2">
                              {firebaseDateToText(tasks.find(task => task.taskName === selectedTask)?.endTime.time, 'MM/DD/YYYY hh:mm a')}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold' }}>
                              {tasks.find(task => task.taskName === selectedTask)?.endTime.location.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Typography variant="h6" gutterBottom>
                      Task Images
                    </Typography>
                    {['start', 'end', 'attached'].map((imageType) => (
                      <Box key={imageType} mb={2}>
                        {selectedTaskImages[imageType] && selectedTaskImages[imageType].length > 0 && (
                          <ImageList cols={1} rowHeight="auto" gap={8}>
                            {selectedTaskImages[imageType].map((image, index) => (
                              <ImageListItem key={index}>
                                <img
                                  src={generateFirebaseImageUrl('task', image)}
                                  alt={`${imageType} image ${index + 1}`}
                                  loading="lazy"
                                  style={{ width: '100%', height: 'auto' }}
                                />
                                <ImageListItemBar
                                  title={imageType.charAt(0).toUpperCase() + imageType.slice(1)}
                                  position="bottom"
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                        )}
                      </Box>
                    ))}
                  </Paper>
                )}
                {/* Completed Tasks Pane */}
                <Paper 
                  elevation={3} 
                  sx={{ 
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: '300px',
                    height: 'calc(100% - 20px)',
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    opacity: 0.9,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Completed Tasks
                  </Typography>
                  <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                    {tasks.map((task, index) => (
                      task.endTime && task.endTime.time && (
                        <ListItem 
                          key={index} 
                          divider 
                          button 
                          onClick={() => handleTaskClick(task.taskName)}
                          selected={selectedTask === task.taskName}
                        >
                          <ListItemText
                            primary={task.taskName}
                            secondary={
                              <>
                                <Typography component="span" variant="body2">
                                  {task.remarks}
                                </Typography>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                                  <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <AccessTime fontSize="small" />
                                    <Typography component="span" variant="caption">
                                      {firebaseDateToText(task.startTime.time, 'hh:mm a')}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <Update fontSize="small" />
                                    <Typography component="span" variant="caption">
                                      {firebaseDateToText(task.endTime.time, 'hh:mm a')}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </>
                            }
                          />
                        </ListItem>
                      )
                    ))}
                  </List>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default AllTimesheetTrack;